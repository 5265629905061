@import "~@styles/variables.scss";
.iconPromotion {
  @apply w-29px h-29px lg:w-49px lg:h-49px
}

.innerHome {
  @apply bg-gray-200 lg:bg-white items-center
}

.session {
  @apply flex-col w-full mt-24px lg:mt-50px
}

.covidBanner {
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100px;
  margin-top: 22px;
  max-width: 1200px;
  @apply w-full
}

.serviceOrRealTech {
  border-radius: 16px;
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  transition: transform 0.5s ease;
  @apply w-full h-full overflow-hidden
}

.company {
  border-radius: 16px;
  border: 1px solid #dedede;
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  transition: transform 0.5s ease;
  @apply w-full p-4px h-90px lg:h-130px
}

.broker {
  border-radius: 9px;
  border: 1px solid #dedede;
  -webkit-transition: -webkit-transform 0.5s ease;
  -moz-transition: -moz-transform 0.5s ease;
  transition: transform 0.5s ease;
  @apply w-full flex flex-col p-6px h-151px lg:h-286px lg:p-12px
}

.fullNameBroker {
  font-size: 7px;
  @apply font-bold font-textBold truncate
}

.imageBroker {
  border-radius: 4px;
  border: 1px solid #dedede;
  @apply w-full h-70px rounded-rd4px lg:h-136px
}

.contactButton {
  border-radius: 9px;
  border: 1px solid #dedede;
  @apply w-full flex flex-row items-center justify-center h-21px lg:h-40px cursor-pointer
}

.contactText {
  font-size: 7px;
  @apply text-black font-bold font-textBold
}

.iconPhone {
  margin-right: 4px;
  @apply w-10px h-10px lg:w-13px lg:h-13px
}

@media (min-width: 1280px) {
  .serviceOrRealTech:hover, .company:hover, .broker:hover {
    border-color: #2459ad !important;
    box-shadow: -1px 0px 8px 0px rgba(36,89,173,0.73) !important;
    transform: scale(1.03);
    transition-duration: 0.5s;
    @apply cursor-pointer
  }

  .broker:hover *.contactButton {
    transition-duration: 0.5s;
    @apply bg-blue-main
  }

  .broker:hover *.contactText {
    transition-duration: 0.5s;
    @apply text-white
  }

  .broker:hover *.iconPhone {
    transition-duration: 0.5s;
    fill: #fff;
  }

  .icon-phone {
    width: 22px;
    height: 22px;
  }
}

.itemPromotion {
  min-width: 60px;
  @apply flex flex-col items-center justify-start cursor-pointer
}

.titlePromotion {
  font-size: 10.08px;
  @apply mt-4px text-center
}

.realEstateCarousel {
  @apply mt-16px lg:mt-24px px-12px lg:px-0
}

.projectRealEstateCarousel {
  @apply flex flex-row w-full h-295px lg:h-450px
}

@media (min-width: 1024px) {
  .titlePromotion {
    font-size: 13.17px;
  }
  .fullNameBroker {
    font-size: 14px;
  }
  .contactText {
    font-size: 13px;
  }
  .covidBanner {
    height: 260px;
    margin-top: 50px;
  }
}



