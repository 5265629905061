@import "~@styles/variables.scss";


.typeOfDemandContainer{
  width: 526px;
  height: 44px;
  border-radius: 8px;
  overflow: hidden;
 @apply flex flex-row border border-gray-300
}
