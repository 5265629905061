@import "~@styles/variables.scss";
.container {
  @apply flex flex-row pr-4px items-start
}

.defaultSizeIcon {
  @apply w-18px h-18px lg:w-24px lg:h-24px
}

.titleNav {
  @apply text-18 text-blue-main lg:uppercase font-bold flex-1
}

.txtViewAll {
  @apply text-12 italic text-blue-main font-textBold font-bold lg:text-18 flex-row items-center whitespace-nowrap ml-8px
}

@media (min-width: 1024px) {
  .titleNav {
    font-size: 28px;
    line-height: 35px !important;
  }
}

.arrowLeft {
  transform: rotate(-90deg);
  @apply w-16px h-16px
}

.badge {
  border-radius: 0 50px 50px 0;
  @apply w-45px h-28px flex items-center mr-8px justify-center bg-blue-main lg:w-67px lg:h-35px lg:mr-16px
}
