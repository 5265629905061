@import "~@styles/variables.scss";

.advanceDropdownContainer{
  border-radius: 8px;
  max-width: 1200px;
  border-color:#2459ad;
  height: 56px;
  @apply flex-1 bg-white flex flex-row items-center border pl-0
}
.suggestionDropdownContainer{
  border-color: #2459ad;
  max-width: 960px;
  max-height: 300px;
  @apply border rounded bg-white overflow-hidden overflow-scroll
}
.searchButton{
  width: 137px;
  background-color: #2459ad;
  border-radius: 4px;
  @apply flex flex-row justify-center items-center h-full space-x-2.5
}
.arrow > path{
  fill: #222222;
}

.input{
  outline:none!important;
  border:none !important;
  height: 48px;
}

.itemSearch {
  @apply w-full flex border-b border-gray-300 items-center space-x-1 truncate px-2 space-x-2
}

.itemSearch:last-child {
  @apply border-b-0
}
