@import "~@styles/variables.scss";


.bottom {
  display: none !important;
}

@media (min-width: 640px) {
  .bottom {
    display: flex !important;
  }
}
