/* purgecss start ignore */

.iconPromotion{
  height:29px;
  width:29px
}

@media (min-width: 1024px){
  .iconPromotion{
    height:49px;
    width:49px
  }
}

.innerHome{
  align-items:center;
  --tw-bg-opacity:1;
  background-color:rgba(229, 231, 235, var(--tw-bg-opacity))
}

@media (min-width: 1024px){
  .innerHome{
    --tw-bg-opacity:1;
    background-color:rgba(255, 255, 255, var(--tw-bg-opacity))
  }
}

.session{
  margin-top:24px;
  width:100%;
  flex-direction:column
}

@media (min-width: 1024px){
  .session{
    margin-top:50px
  }
}

.covidBanner{
  background-position:center;
  background-size:cover;
  background-repeat:no-repeat;
  height:100px;
  margin-top:22px;
  max-width:1200px;
  width:100%
}

.serviceOrRealTech{
  border-radius:16px;
  transition:transform .5s ease;
  height:100%;
  width:100%;
  overflow:hidden
}

.company{
  border-radius:16px;
  border:1px solid #dedede;
  transition:transform .5s ease;
  height:90px;
  width:100%;
  padding:4px
}

@media (min-width: 1024px){
  .company{
    height:130px
  }
}

.broker{
  border-radius:9px;
  border:1px solid #dedede;
  transition:transform .5s ease;
  display:flex;
  height:151px;
  width:100%;
  flex-direction:column;
  padding:6px
}

@media (min-width: 1024px){
  .broker{
    height:286px;
    padding:12px
  }
}

.fullNameBroker{
  font-size:7px;
  overflow:hidden;
  text-overflow:ellipsis;
  white-space:nowrap;
  font-family:Mulish, bold;
  font-weight:700
}

.imageBroker{
  border-radius:4px;
  border:1px solid #dedede;
  height:70px;
  width:100%;
  border-radius:4px
}

@media (min-width: 1024px){
  .imageBroker{
    height:136px
  }
}

.contactButton{
  border-radius:9px;
  border:1px solid #dedede;
  display:flex;
  height:21px;
  width:100%;
  cursor:pointer;
  flex-direction:row;
  align-items:center;
  justify-content:center
}

@media (min-width: 1024px){
  .contactButton{
    height:40px
  }
}

.contactText{
  font-size:7px;
  font-family:Mulish, bold;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(0, 0, 0, var(--tw-text-opacity))
}

.iconPhone{
  margin-right:4px;
  height:10px;
  width:10px
}

@media (min-width: 1024px){
  .iconPhone{
    height:13px;
    width:13px
  }
}

@media(min-width: 1280px){
  .serviceOrRealTech:hover,.company:hover,.broker:hover{
    border-color:#2459ad !important;
    box-shadow:-1px 0px 8px 0px rgba(36,89,173,.73) !important;
    transform:scale(1.03);
    transition-duration:.5s
  }

  .serviceOrRealTech:hover, .company:hover, .broker:hover{
    cursor:pointer
  }

  .broker:hover *.contactButton{
    transition-duration:.5s;
    --tw-bg-opacity:1;
    background-color:rgba(36, 89, 173, var(--tw-bg-opacity))
  }

  .broker:hover *.contactText{
    transition-duration:.5s;
    --tw-text-opacity:1;
    color:rgba(255, 255, 255, var(--tw-text-opacity))
  }

  .broker:hover *.iconPhone{
    transition-duration:.5s;
    fill:#fff
  }

  .icon-phone{
    width:22px;
    height:22px
  }
}

.itemPromotion{
  min-width:60px;
  display:flex;
  cursor:pointer;
  flex-direction:column;
  align-items:center;
  justify-content:flex-start
}

.titlePromotion{
  font-size:10.08px;
  margin-top:4px;
  text-align:center
}

.realEstateCarousel{
  margin-top:16px;
  padding-left:12px;
  padding-right:12px
}

@media (min-width: 1024px){
  .realEstateCarousel{
    margin-top:24px;
    padding-left:0px;
    padding-right:0px
  }
}

.projectRealEstateCarousel{
  display:flex;
  height:295px;
  width:100%;
  flex-direction:row
}

@media (min-width: 1024px){
  .projectRealEstateCarousel{
    height:450px
  }
}

@media(min-width: 1024px){
  .titlePromotion{
    font-size:13.17px
  }

  .fullNameBroker{
    font-size:14px
  }

  .contactText{
    font-size:13px
  }

  .covidBanner{
    height:260px;
    margin-top:50px
  }
}

/* purgecss end ignore */