/* purgecss start ignore */

.container{
  display:flex;
  flex-direction:row;
  align-items:flex-start;
  padding-right:4px
}

.defaultSizeIcon{
  height:18px;
  width:18px
}

@media (min-width: 1024px){
  .defaultSizeIcon{
    height:24px;
    width:24px
  }
}

.titleNav{
  flex:1 1 0%;
  font-size:18px;
  line-height:26px;
  font-weight:700;
  --tw-text-opacity:1;
  color:rgba(36, 89, 173, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .titleNav{
    text-transform:uppercase
  }
}

.txtViewAll{
  margin-left:8px;
  flex-direction:row;
  align-items:center;
  white-space:nowrap;
  font-family:Mulish, bold;
  font-size:12px;
  font-weight:700;
  font-style:italic;
  --tw-text-opacity:1;
  color:rgba(36, 89, 173, var(--tw-text-opacity))
}

@media (min-width: 1024px){
  .txtViewAll{
    font-size:18px;
    line-height:26px
  }
}

@media(min-width: 1024px){
  .titleNav{
    font-size:28px;
    line-height:35px !important
  }
}

.arrowLeft{
  transform:rotate(-90deg);
  height:16px;
  width:16px
}

.badge{
  border-radius:0 50px 50px 0;
  margin-right:8px;
  display:flex;
  height:28px;
  width:45px;
  align-items:center;
  justify-content:center;
  --tw-bg-opacity:1;
  background-color:rgba(36, 89, 173, var(--tw-bg-opacity))
}

@media (min-width: 1024px){
  .badge{
    margin-right:16px;
    height:35px;
    width:67px
  }
}

/* purgecss end ignore */